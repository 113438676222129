<template>
    <FormTemplate
        @submit="validate"
    >
        <template v-slot:desc>
            Номер телефона уже используется. Для продолжения введите код из СМС или используйте другой номер телефона для регистрации.
        </template>
        <Code
            ref="code"
            type="auth"
            :getCode="getCode"
            @ready="submit"
            notInitSend
        />
    </FormTemplate>
</template>

<script>
import { mapGetters } from 'vuex'

import Store from '@/store'

import Storage from '@/ext/storage/Storage'

export default {
    name: 'Auth',
    computed: {
        ...mapGetters({
            user: ['application/user'],
            code_hash: ['application/code']
        }),
        code() {
            return this.$refs.code?.form?.code
        }
    },
    methods: {
        async getCode() {
            await this.$store.dispatch('application/send', {
                contactData: this.user.contactData
            })
        },
        validate() {
            this.$refs.code.validate()

            this.$refs.code.isValid && this.submit()
        },
        async submit() {
            const { wrongCode } = await this.$store.dispatch('application/send', {
                contactData: {
                    ...this.user.contactData,
                    code_hash: this.code_hash,
                    code: this.code,
                    phone: this.user.contactData.phone || Storage.get('user_phone'),
                }
            })

            if (!wrongCode) {
                await this.$store.dispatch('application/update')
                this.$store.commit('application/clearCode')
                this.$refs.code.resetTimer()
                this.$router.push({ name: 'LoanContact' })
                Storage.delete('user_phone');
            } else {
                this.$refs.code.validate()
                this.$refs.code.formErrors.code = 'Неверный код'
            }
        }
    },
    components: {
        FormTemplate: () => import('@/templates/form/Form'),
        Code: () => import('@/components/form/code/Code'),
    },
    beforeRouteEnter(to, from, next) {
        // @TODO внедрить проверку на разрешенный переход на эту страницу
        const code = Store.getters['application/code']

        if (code) {
            next()
        } else {
            Store.commit('application/load', false)
            next({ name: 'LoanPrimary' })
        }
    }
}
</script>